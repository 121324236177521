const AMIO_WEBCHAT_IFRAME_URL = 'https://chat-widget.static-amio.com'
const CONTAINER_ID = '_amio_webchat_container'
const Z_INDEX_DEFAULT = '100'
const Z_INDEX_PLACEHOLDER = '<z-index-value>'
const BUTTON_WRAPPER_WIDTH_PLACEHOLDER = '<wide-button-wrapper-width>'
const BUTTON_WRAPPER_HEIGHT_PLACEHOLDER = '<button-wrapper-height>'
const IFRAME_OFFSET_X_PLACEHOLDER = '<iframe-offset-x>'
const IFRAME_OFFSET_Y_PLACEHOLDER = '<iframe-offset-y>'
// a variable storing parent's method, which should be executed in case of callback button being used
let callbackFunction = null
let onUserEventCallbackFunction = null

const FULL_SCREEN_STYLES = `
    position: fixed;
    width: 100%;
    height: 100%;
    max-height: 100%;
    bottom: 0;
    right: 0;
    border-radius: 0;
    margin-right: 0 !important;
    margin-bottom: 0 !important;
    z-index: ${Z_INDEX_PLACEHOLDER};
`

const STYLE_TEXT = `
.amio_iframe {
  background: none;
  border: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  color-scheme: normal;
}

.amio_chat_wrapper_open {
  margin-bottom: 10px;
  margin-right: 10px;
  position: fixed;
  right: ${IFRAME_OFFSET_X_PLACEHOLDER};
  bottom: ${IFRAME_OFFSET_Y_PLACEHOLDER};
  max-width: 100%;
  height: 800px;
  max-height: calc(100% - 100px);
  min-height: 400px;
  z-index: ${Z_INDEX_PLACEHOLDER};
}

.amio_chat_wrapper_open-left {
  width: 466px;
}

.amio_chat_wrapper_open-top {
  width: 421px;
}

.amio_chat_wrapper_pop_up {
  margin-bottom: 10px;
  margin-right: 10px;
  position: fixed;
  right: ${IFRAME_OFFSET_X_PLACEHOLDER};
  bottom: ${IFRAME_OFFSET_Y_PLACEHOLDER};
  width: 340px;
  max-width: 100%;
  height: 400px;
  max-height: calc(100% - 100px);
  z-index: ${Z_INDEX_PLACEHOLDER};
}

.amio_chat_wrapper_full {
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
}

.forced-full-screen {
  ${FULL_SCREEN_STYLES}
}

.amio_chat_wrapper_closed {
  margin-bottom: 10px;
  margin-right: 10px;
  position: fixed;
  right: ${IFRAME_OFFSET_X_PLACEHOLDER};
  bottom: ${IFRAME_OFFSET_Y_PLACEHOLDER};
  width: ${BUTTON_WRAPPER_WIDTH_PLACEHOLDER};
  height: ${BUTTON_WRAPPER_HEIGHT_PLACEHOLDER};
  max-height: 100px;
  z-index: ${Z_INDEX_PLACEHOLDER};
}

.hidden {
  display: none;
}

.amio_chat_wrapper_closed.wide {
  width: ${BUTTON_WRAPPER_WIDTH_PLACEHOLDER};
  height: ${BUTTON_WRAPPER_HEIGHT_PLACEHOLDER};
}

@media (max-width: 420px) {
  .amio_chat_wrapper_open {
    ${FULL_SCREEN_STYLES}
  }

  .amio_chat_wrapper_closed.wide {
    width: 100px;
  }
}
`

const PARAMS_CONFIG = [
  { name: 'channelId' },
  { name: 'externalContactId' },
  { name: 'logoUrl', formatter: (value) => encodeURIComponent(value) },
  { name: 'theme' },
  { name: 'startOpened' },
  { name: 'lang' },
  { name: 'wideButton' },
  { name: 'storageType' },
  { name: 'hideCloseButton' },
  { name: 'hideLauncher' },
  { name: 'forceFullScreen' },
  { name: 'showVoice' },
  { name: 'hideHeader' },
  { name: 'disablePopUpMessages' },
  { name: 'disableAmioLink' },
  { name: 'launcherImageUrl', formatter: (value) => encodeURIComponent(value) },
  { name: 'chatWindowPosition' }
]

function createStyleText (params) {
  const buttonWidthStyleText = createStyleButtonDimensions('270px', '100px')
  const zIndex = params.zIndex ? params.zIndex : Z_INDEX_DEFAULT
  const zIndexStyleText = buttonWidthStyleText.replace(new RegExp(Z_INDEX_PLACEHOLDER, 'g'), zIndex)
  return createStyleIframeOffset(zIndexStyleText, params)
}

function createStyleButtonDimensions (width, height) {
  const updatedStyleText = STYLE_TEXT.replace(new RegExp(BUTTON_WRAPPER_WIDTH_PLACEHOLDER, 'g'), width).replace(new RegExp(BUTTON_WRAPPER_HEIGHT_PLACEHOLDER, 'g'), height)
  return updatedStyleText
}

function createStyleIframeOffset (styleText, params) {
  const offsetX = params.iframeOffsetX ? params.iframeOffsetX : '18px'
  const updatedStyleText = styleText.replace(new RegExp(IFRAME_OFFSET_X_PLACEHOLDER, 'g'), offsetX)
  const offsetY = params.iframeOffsetY ? params.iframeOffsetY : '18px'
  return updatedStyleText.replace(new RegExp(IFRAME_OFFSET_Y_PLACEHOLDER, 'g'), offsetY)
}

function createIframeUrl (params) {
  const arrayedParams = []
  PARAMS_CONFIG.forEach(({ name, formatter }) => {
    if (!params[name]) return

    const value = formatter ? formatter(params[name]) : params[name]
    arrayedParams.push(`${name}=${value}`)
  })

  // undocumented parameter that allows us to test staging chat widget
  const iframeUrl = params._overrideWebchatUrl ? params._overrideWebchatUrl : AMIO_WEBCHAT_IFRAME_URL

  return iframeUrl + '/?' + arrayedParams.join('&')
}

function init (params) {
  const styleElement = document.createElement('style')
  styleElement.type = 'text/css'
  styleElement.appendChild(document.createTextNode(createStyleText(params)))
  document.head.appendChild(styleElement)

  const iframe = document.createElement('iframe')
  iframe.src = createIframeUrl(params)
  iframe.className = 'amio_iframe'
  if (params.showVoice === true) iframe.allow = 'microphone'

  const div = document.createElement('div')
  if (params.wideButton === true) div.classList.add('wide')

  if (params.forceFullScreen === true) div.classList.add('forced-full-screen')

  const wrapperWidthClass = _getWrapperOpenWidthClass(params.chatWindowPosition)
  const closedClassList = ['amio_chat_wrapper_closed']
  if (params.hideLauncher === 'always') {
    closedClassList.push('hidden')
  }
  if (params.startOpened === true) {
    div.classList.add('amio_chat_wrapper_open', wrapperWidthClass)
  } else {
    div.classList.add(...closedClassList)
  }
  console.log(div)

  div.id = CONTAINER_ID
  div.appendChild(iframe)

  document.body.appendChild(div)

  window.addEventListener('message', (event) => {
    if (!event.data) return

    const removeStyleProperties = () => {
      document.getElementById(CONTAINER_ID).style.removeProperty('width')
      document.getElementById(CONTAINER_ID).style.removeProperty('height')
    }

    if (event.data.chatState === 'open') {
      document.getElementById(CONTAINER_ID).classList.remove(...closedClassList)
      document.getElementById(CONTAINER_ID).classList.remove('amio_chat_wrapper_full')
      document.getElementById(CONTAINER_ID).classList.add('amio_chat_wrapper_open', wrapperWidthClass)
      removeStyleProperties()
    } else if (event.data.chatState === 'closed') {
      document.getElementById(CONTAINER_ID).classList.remove('amio_chat_wrapper_open', wrapperWidthClass)
      document.getElementById(CONTAINER_ID).classList.add(...closedClassList)
    } else if (event.data.chatState === 'pop-up') {
      document.getElementById(CONTAINER_ID).classList.remove(...closedClassList)
      document.getElementById(CONTAINER_ID).classList.add('amio_chat_wrapper_pop_up')
      if (event.data.iframeWidth && event.data.iframeHeight) {
        document.getElementById(CONTAINER_ID).style.width = event.data.iframeWidth
        document.getElementById(CONTAINER_ID).style.height = event.data.iframeHeight
      }
    } else if (event.data.chatState === 'pop-up-closed') {
      document.getElementById(CONTAINER_ID).classList.remove('amio_chat_wrapper_pop_up')
      document.getElementById(CONTAINER_ID).classList.add(...closedClassList)
      removeStyleProperties()
    } else if (event.data.chatState === 'imageExpand') {
      document.getElementById(CONTAINER_ID).classList.remove(...closedClassList)
      document.getElementById(CONTAINER_ID).classList.add('amio_chat_wrapper_open', 'amio_chat_wrapper_full', wrapperWidthClass)
    } else if (event.data.chatState === 'mounted') {
      setBrowserUrl(parent.location.href)
    } else if (event.data.callback) {
      if (callbackFunction) {
        callbackFunction(event.data.callback)
      }
    } else if (event.data.launcherWidth && event.data.launcherHeight) {
      styleElement.appendChild(document.createTextNode(createStyleButtonDimensions(event.data.launcherWidth, event.data.launcherHeight)))
    } else if (event.data.userEvent) {
      fireUserEvent(event.data.userEvent)
    }
  })
}

function open () {
  _notifyChild({ isChatOpen: true })
}

function close () {
  _notifyChild({ isChatOpen: false })
}

function triggerEvent (eventId) {
  _notifyChild({ eventId })
}

function onCallbackButtonPressed (func) {
  callbackFunction = func
}

function onUserEvent (func) {
  onUserEventCallbackFunction = func
}

function fireUserEvent (event) {
  if (onUserEventCallbackFunction) onUserEventCallbackFunction(event.name, event.params)
}

function setBrowserUrl (url) {
  setMetadata({ browserUrl: url })
}

function setMetadata (metadata) {
  if (!metadata || typeof metadata !== 'object' || Array.isArray(metadata)) {
    throw new Error('metadata are not an object')
  }

  // a dirty workaround for a case where setMetadata is called immediately after init
  setTimeout(() => {
    _notifyChild({ metadata })
  }, 1000)
}

function _notifyChild (message) {
  const iframeEl = document.getElementsByClassName('amio_iframe')[0]
  iframeEl.contentWindow.postMessage(message, '*')
}

function _getWrapperOpenWidthClass (chatWindowPosition) {
  return `amio_chat_wrapper_open-${chatWindowPosition === 'left' ? 'left' : 'top'}`
}

export default {
  init,
  open,
  close,
  triggerEvent,
  onCallbackButtonPressed,
  onUserEvent,
  setMetadata,
  setBrowserUrl
}
